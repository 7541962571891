import { type Editor } from "@tiptap/react";
import { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from "react";

const EditorContext = createContext<{ editor: Editor | null; setEditor: (editor: Editor | null) => void }>({
	editor: null,
	setEditor: () => {},
});

export const useEditorContext = () => useContext(EditorContext);

export const useEditor = () => useEditorContext().editor;

export const useSetEditor = (editor: Editor | null) => {
	const { setEditor } = useEditorContext();
	useEffect(() => {
		setEditor(editor);
	}, [editor, setEditor]);
};

export const EditorProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
	const [editor, setEditor] = useState<Editor | null>(null);
	const value = useMemo(() => ({ editor, setEditor }), [editor, setEditor]);

	return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
};
